<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__header ls-center">
        <h3>{{ this.requestStatus }} Specialist Requests</h3>
      </div>
      <div class="ps-section__content">
        <table class="table ps-table ps-table--invoices table-bordered">
          <thead class="thead-light">
            <tr>
              <th scope="col">id</th>
              <th scope="col">Request Name</th>
              <th scope="col">Status</th>
              <th scope="col">Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="myRequest in myRequests" :key="myRequest.id">
              <th scope="row">{{ myRequest.id }}</th>
              <td>{{ myRequest.body }}</td>
              <td>{{ myRequest.status }}</td>
              <td>{{ myRequest.updated_at }}</td>
              <td>
                <router-link
                  :to="{ name: 'runner_request', params: { id: myRequest.id } }"
                  >View</router-link
                >
                /
                <router-link
                  :to="{
                    name: 'runner_request_edit',
                    params: { id: myRequest.id },
                  }"
                  >Edit</router-link
                >
                / Cancel
              </td>
            </tr>
          </tbody>
          <tfoot v-if="!myRequests || myRequests.length === 0">
            <tr>
              <td colspan="7" class="text-center m-4 text-danger">
                No Specialist requests available
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "SpecialistRequest",
  mounted() {
    this.getRequestStatus();
    this.getRunnersDetails();
  },
  data() {
    return {
      requestStatus: "",
      runnerCategories: [],
      myRequests: [],
      runners: 0,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },

  watch: {
    "$route.params.status": {
      handler: function (status) {
        this.getRequestStatus();
        this.getRunnersDetails();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getRequestStatus() {
      //alert(this.$route.params.status);
      switch (this.$route.params.status) {
        case "pending":
          this.requestStatus = "Pending";
          break;
        case "completed":
          this.requestStatus = "Completed";
          break;
        case "in-progress":
          this.requestStatus = "In Progress";
          break;
        default:
        //redirect person to previous page
      }
    },

    getRunnersDetails() {
      axios.get("v1/getrunner/get-runners").then((response) => {
        // console.log(
        //   "Get Runner Requests : " + JSON.stringify(response.data.data)
        // );
        var requests = response.data.data;
        for (var i = 0; i < requests.length; i++) {
          if (
            this.$route.params.status !== "" &&
            requests[i].status === this.$route.params.status
          ) {
            this.myRequests.push(requests[i]);
          } else {
            this.myRequests.push(requests[i]);
          }
        }
        // console.log("My Requests : " + JSON.stringify(this.myRequests));
        // this.pendingRequests = response.data.data.length;
      });
    },
    onCreateRunnerRequest() {
      //the request method to save the request
      let data = {
        runner_category_id: this.runnerRequest.runner_category_id,
        body: this.runnerRequest.description,
      };

      axios
        .post("v1/getrunner/get-runners", data)
        .then((response) => {
          this.isLoading = false;
          if (response.status === 200 || response.status === 201) {
            this.message = "User successfully created";
            // setTimeout(function(){
            //   this.$router.push({name: 'admin_users'});
            // }, 500);
            this.$toasted.show("Request successfully created. ");
          }
        })
        .catch((error) => {
          this.$toasted.error("Saving failed, please try again");
        });
    },
  },
};
</script>
